import React, { FC, useMemo } from 'react'

import { IPaymentMethod } from 'interfaces/IPaymentMethod'
import './paymentMethod.scss'

interface IPaymentMethodProps extends IPaymentMethod {
	sum?: number,
	handleClick: Function
}

const PaymentMethod: FC<IPaymentMethodProps> = (
	{ id,
		ico,
		min,
		sum = 0,
		handleClick
	}) => {

	const paymentMethodClasses = useMemo(() => {
		return `payment-method ${sum < min && 'payment-method_disabled'}`
	}, [min])

	return (
		<div className={paymentMethodClasses} onClick={() => handleClick(id)}>
			{ ico && <img src={ico} alt={id} /> }
			{ (sum < min) && <span className="payment-method__warning">Минимум {min} руб.</span> }
			<div className="payment-method__bg" />
		</div>
	)
}

export default PaymentMethod
