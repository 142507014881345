import React, { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import Form from '../Form/Form'
import { packPayment, userPayment } from 'store/app/actions'
import { getPacksList, getPaymentsList, getRolesList } from 'store/app/selectors'
import IServer from 'interfaces/IServer'
import './user.scss'
import Transition from 'components/Transition/Transition'
import { getUserData } from 'store/user/selectors'
import Input from 'components/Entities/Form/Input/Input'
import Button from 'components/Entities/Form/Button/Button'
import {useErrorMessage} from 'hooks/useErrorMessage'
import Select from 'components/Entities/Form/Select/Select'
import {useBonus} from '../../../../hooks/useBonus';

import Confirm from 'components/Donate/Confirm/Confirm'
import Packs from 'components/Packs/Packs'
import Services from 'components/Donate/Services/Services'

import { IPaymentState } from 'interfaces/IPaymentState'
import {IDonatePack} from '../../../../interfaces/IDonatePack';
interface IUserPaymentState extends IPaymentState {
	role?: string | null,
	paymentType?: string,
	donateType?: string,
}

interface IUserProps {
	server: IServer
}

const User: FC<IUserProps> = ({ server }) => {
	const dispatch = useDispatch()
	const roles = useSelector(getRolesList)
	const [ paymentState, setPaymentState ] = useState<IUserPaymentState>({
		key: '',
		login: '',
		sum: 0,
		sumWithBonus: 0,
		role: null,
		server: server.name
	})
	const [ confirm, setConfirm ] = useState(false)
	const user = useSelector(getUserData)
	const packs = useSelector(getPacksList)
	const [ calculateBonus ] = useBonus()

	const [ modal, setModal ] = useState(false)
	const [ pack, setPack ] = useState<IDonatePack|null>(null)

	const paymentMethods = useSelector(getPaymentsList)

	useEffect(() => {
		setPaymentState((prevState => {
			return {
				...prevState,
				sumWithBonus: calculateBonus(paymentState.sum)
			}
		}))
	}, [paymentState.sum])


	const serverRoles = useMemo(() => {
		return roles.reduce((accumulator: [], serverItem: IServer) => {
			//@ts-ignore
			return serverItem.server === server.id ? [ ...accumulator, serverItem ] : accumulator
		}, [])
	}, [roles])

	const handleOneSubmit = (data: any) => {
		return userPayment(data, confirm)
	}

	const handleSuccess = (key: string, login: string, sum: number) => {
		setPaymentState((prevState) => {
			return {
				key,
				login,
				sum,
				sumWithBonus: prevState.sumWithBonus,
				role: null,
				paymentType: 'Пополнение счета',
				donateType: 'Пополнение',
				server: server.name
			}
		})
		setConfirm(true)
	}

	const handleClick = async (id: string) => {
		const data = {
			payment_data: '',
			payment_method: '',
		}
		const { key, role } = paymentState
		data.payment_data = key
		data.payment_method = id
		if (role) {
			try {
				const { success, link }: any = await dispatch(packPayment(data))
				if (success) {
					window.location.href = link
				}
			} catch (e) {}
		} else {
			try {
				const { success, link }: any = await dispatch(userPayment(data))
				if (success) {
					window.location.href = link
				}
			} catch (e) {}
		}
	}

	const {
		register,
		handleSubmit,
		errors,
		formState,
		trigger,
		getValues,
		setError,
	} = useForm({
		mode: "onChange",
		criteriaMode: "all",
		defaultValues: {
			email: user.email ? user.email : null,
		}
	})
	const [ errorMessage, setErrorMessage ] = useErrorMessage(formState, errors)

	const handleDonatePack = (pack: IDonatePack) => {
		if (serverRoles.length === 0) {
			alert('У вас ещё нет персонажей на выбранном сервере!')
			return
		}
		setPack(pack)
		setModal(true)
		setPaymentState((prevState) => {
			return {
				...prevState,
				sum: pack.price,
				paymentType: `Донат набор «${pack.name}»`,
				donateType: 'Донат набор',
			}
		})
	}

	const handleCloseModal = () => {
		setModal(false)
	}

	const handleBuyPack = async () => {
		const data = {
			mid: getValues('mid'),
			//@ts-ignore
			pack_id: pack.id
		}
		try {
			const { success, payment_data }: any = await dispatch(packPayment(data))
			if (success) {
				setPaymentState((prevState) => {
					return {
						...prevState,
						key: payment_data,
						login: user.login,
						role: getValues('mid')
					}
				})
			}
		} catch (e) {}
		setConfirm(true)
		setModal(false)
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	useEffect(() => {
		const $el = document.getElementsByTagName('html')[0]
		if (modal) {
			$el.classList.add('html_locked')
		} else {
			$el.classList.remove('html_locked')
		}
	}, [modal])

	const findByMid = (mid: any) => {
		// eslint-disable-next-line eqeqeq
		const role = serverRoles.find((role: any) => role.mid == mid)
		if (role) {
			return role.name
		}
	}

	const handleBack = () => {
		setConfirm(false)
	}

	return (
		<Fragment>
			<div className="user-donate">
				<div className="user-donate__col user-donate__col_main">
					<Transition dependencyKey={confirm}>
						{ !confirm &&
							<div className="udonate-root">
								<div className="udonate-root__form">
									<Form
											submit={handleOneSubmit}
											server={server}
											disableLogin={true}
											onSuccess={handleSuccess}
											prevSum={paymentState.sum}
									/>
								</div>
								<div className="udonate-root__roles">
									<div className="udonate-root__balance">
										На счете: <strong>{ user.donate} DP</strong>
									</div>
									{ serverRoles.length === 0 &&
									<p className="roles__empty">
										Персонажей нет
									</p>
									}
									{ serverRoles.map(({ mid, name, last_login, level }: any) => (
										<div className="role-item" key={mid}>
											<div className="role-item__pic" />
											<div className="role-item__container">
												<header className="role-item__header">
													<div className="role-item__level">
														<span>
															{ level }
														</span>
													</div>
													<p className="role-item__name">
														{ name }
													</p>
												</header>
												<p className="role-item__ll">
													Последний вход:
												</p>
												<p className="role-item__dt">
													{ last_login }
												</p>
											</div>
										</div>
									))}
								</div>
							</div>
						}
						{ confirm &&
							<Confirm
								handlePaymentClick={handleClick}
								paymentState={paymentState}
								handleBack={handleBack}
								paymentMethods={paymentMethods}
								sum={paymentState.sum}
							/>
						}
					</Transition>
				</div>
				<div className="user-donate__col user-donate__col_buns">
					<div className="guest-donate__services-root user-donate__services">
						<Services />
					</div>
					<div className="packs-root">
						<Packs packs={packs} handleClick={handleDonatePack} />
					</div>
				</div>
			</div>
			<Transition dependencyKey={modal}>
				{ modal &&
        <div className="modal">
          <div className="modal-root">
            <div className="modal__container">
              <i
                  onClick={handleCloseModal}
                  className="icon__close-modal modal__control"
              />
              <form
									className="feedback-form"
									onSubmit={handleSubmit(handleBuyPack)}
							>
                <div className="ff-group">
                  <Input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="off"
                      label="ваш e-mail"
                      validate={register({ required: 'Введите e-mail!'})}
                  />
                </div>
								<div className="ff-group">
									<Select
                      id="mid"
                      name="mid"
											payments={serverRoles}
                      validate={register({ required: 'Выберите персонажа'})}
									/>
								</div>
                <Transition dependencyKey={errorMessage}>
                  <p className="donate-error form-error mt-18">
										{ errorMessage }
                  </p>
                </Transition>
              </form>
            </div>
            <Button
                className="feedback-form__submit"
                disabled={!formState.isValid}
                onClick={handleBuyPack}
            >
              Купить { paymentState.paymentType } за { paymentState.sum } руб.
            </Button>
          </div>
        </div>
				}
			</Transition>
		</Fragment>
	)
}

export default User
