  import React, { FC, memo, useCallback, useMemo } from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

  import Button from '../../Entities/Form/Button/Button'
import PaymentMethod from './PaymentMethod/PaymentMethod'
import { IPaymentMethod } from 'interfaces/IPaymentMethod'
import { IPaymentMethods } from 'interfaces/IPaymentMethods'
import './paymentMethods.scss'

interface IPaymentMethodsProps extends IPaymentMethods {
	sum?: number,
	handlePaymentClick: Function,
  togglePromo: () => void
}

const PaymentMethods: FC<IPaymentMethodsProps> = (
	{ paymentMethods,
		sum = 0,
		handlePaymentClick,
    togglePromo,
	}) => {

	const handleClick = useCallback((id: string) => {
		return handlePaymentClick(id)
	}, [])

	const simpleBarStyles = useMemo(() => {
		return {
			maxHeight: 364
		}
	}, [])

  const handleToggleClick = () => {
    togglePromo()
  }

	return (
		<div className="donate-payments">
      <div className="donate-payments__header">
        <h2 className="donate-payments__title">
          Выберите<br />
          Способ оплаты
        </h2>

        <p className="donate-payments__or">
          или
        </p>

        <div className="donate-payments__action">

          <Button
            className="donate-payments__activate"
            onClick={() => handleToggleClick()}
          >
            активировать ключ
          </Button>
          {/*<button*/}
          {/*  type="button"*/}
          {/*  className="donate-payments__activate"*/}
          {/*  onClick={() => handleToggleClick()}*/}
          {/*>*/}
          {/*  активировать ключ*/}
          {/*</button>*/}
        </div>
      </div>
			<div className="donate-payment__root layout__correction">
				<SimpleBar
					autoHide={false}
					style={simpleBarStyles}
				>
					<div className="donate-payments__container">
						{ paymentMethods.map(({ id, ico, min, name }: IPaymentMethod) => (
							<PaymentMethod
								key={id}
								id={id}
								ico={ico}
								min={min}
								name={name}
								sum={sum}
								handleClick={handleClick}
							/>
						))}
					</div>
				</SimpleBar>
			</div>
		</div>
	)
}

export default memo(PaymentMethods)
