import React, { FC, Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import ReactCountUp from 'react-countup'

import Transition from 'components/Transition/Transition'
import Input from 'components/Entities/Form/Input/Input'
import Button from 'components/Entities/Form/Button/Button'
import { getUserData } from 'store/user/selectors'
import { useErrorMessage } from 'hooks/useErrorMessage'
import { useBonus } from 'hooks/useBonus'
import { requestDispatch } from 'helpers/request'
import IServer from 'interfaces/IServer'

interface IFormProps {
	submit: Function,
	onSuccess?: Function,
	server?: IServer,
	disableLogin?: boolean,
	prevSum?: number,
}

const Form: FC<IFormProps> = (
	{ submit,
		onSuccess,
		server,
		disableLogin = false,
		prevSum = 0,
	}) => {
	// Блокирование кнопки (зависимость от запроса)
	const [ disable, setDisable ] = useState(false)
	const [ sumWithBonus, setSumWithBonus ] = useState(0)
	// Данные пользователя
	const userData = useSelector(getUserData)
	const {
		register,
		handleSubmit,
		errors,
		formState,
		trigger,
		getValues,
		setError,
		watch,
	} = useForm({
		mode: "onChange",
		criteriaMode: "all",
		defaultValues: {
			login: userData.login || localStorage.getItem('login'),
			email: userData.email || localStorage.getItem('email'),
			sum: prevSum || localStorage.getItem('sum') || 0,
			server_id: 0,
		}
	})
	const [ errorMessage, setErrorMessage ] = useErrorMessage(formState, errors)
	let sum = watch('sum')
	const [ calculateBonus ] = useBonus()

	useEffect(() => {
		trigger()
	}, [trigger])

	useEffect(() => {
		setSumWithBonus(calculateBonus(sum))
	}, [sum])

	const onSubmit = async () => {
		const data = getValues()
		if (server) {
			//@ts-ignore
			data.server = server.id
		}
		localStorage.setItem('login', data.login)
		localStorage.setItem('email', data.email)
		localStorage.setItem('sum', '500')
		setDisable(true)
		try {
			const response: any = await requestDispatch(submit(data), onSubmit)
			if (!response.success) {
				throw { message: response.error_text, errors: response.errors }
			}
			const { success, payment_data } = response
			if (success && onSuccess) {
				onSuccess(payment_data, data.login, data.sum)
			}
		} catch ({ message, errors }) {
			setDisable(false)
			if (errors) {
				const firstKey = Object.keys(errors)[0]
				if (firstKey) {
					//@ts-ignore
					setError(firstKey, { type: "backendError", message: errors[firstKey] });
				}
			}
		}
	}

	return (
		<Fragment>
			<div className="donate-main__root">
				<form
					className="donate-main__form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="form-group">
						<Input
								id="login"
								name="login"
								label="Ваш логин:"
								autoComplete="off"
								validate={register({ required: 'Введите логин!'})}
								disabled={disableLogin}
						/>
					</div>
					<div className="form-group">
						<Input
								id="email"
								name="email"
								type="email"
								label="Введите email:"
								autoComplete="off"
								validate={
									register({
										required: 'Введите email!',
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: 'Некорректный email'
										}
									})
								}
						/>
					</div>
					<div className="form-group mt-55">
						<div className="input-wrapper">
							<label className="input-label">
								Сумма пополнения:
							</label>
							<div className="input-container">
								<input
									className="input-control input-control_number"
									name="sum"
									type="number"
									autoComplete="off"
									ref={
										register({
											required: 'Введите сумму!',
											min: {
												value: 1,
												message: 'Не менее одного рубля!'
											}
										})
									}
								/>
								<span className="input-ruble">₽</span>
							</div>
						</div>
					</div>
					<div className="sum-amount">
						Вы получите <span>
													<ReactCountUp
														end={sumWithBonus}
														preserveValue={true}
														duration={1}
													/> DP
												</span>
					</div>
					<input
						type="hidden"
						name="server_id"
						value="0"
					/>
				</form>
			</div>
			<Button disabled={!formState.isValid || disable} onClick={onSubmit}>
				Перейти к оплате
			</Button>
			<Transition dependencyKey={errorMessage}>
				<p className="donate-error form-error mt-18">
					{ errorMessage }
				</p>
			</Transition>
		</Fragment>
	)
}

export default Form
