import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { guestPayment } from 'store/app/actions'
import { getPaymentsList } from 'store/app/selectors'
import Transition from 'components/Transition/Transition'
import Form from '../Form/Form'
import Confirm from 'components/Donate/Confirm/Confirm'
import Services from 'components/Donate/Services/Services'
import { useBonus } from 'hooks/useBonus'
import './guest.scss'

import { IPaymentState } from 'interfaces/IPaymentState'
import { IGuestPaymentRequest } from 'interfaces/requests/IGuestPaymentRequest'

const Guest: FC = () => {
	const dispatch = useDispatch()
	const paymentMethods = useSelector(getPaymentsList)
	const [ confirm, setConfirm ] = useState(false)
	const [ calculateBonus ] = useBonus()
	const [ paymentState, setPaymentState ] = useState<IPaymentState>({
		key: '',
		login: '',
		sum: 0,
		sumWithBonus: 0,
	})

	const handleToPayment = (data: IGuestPaymentRequest) => {
		return guestPayment(data, confirm)
	}

	const handleSuccess = (key: string, login: string, sum: number): void => {
		const sumWithBonus = calculateBonus(sum)
		setPaymentState((prevValue) => {
			return {
				key,
				login,
				sum,
				sumWithBonus,
			}
		})
		setConfirm(true)
	}

	const handlePaymentClick = async (id: string) => {
		const data = {
			payment_data: '',
			payment_method: '',
		}
		data.payment_data = paymentState.key
		data.payment_method = id
		try {
			const { success, link }: any = await dispatch(guestPayment(data))
			if (success) {
				window.location.href = link
			}
		} catch (e) {}
	}

	const handleBack = () => {
		setConfirm(false)
	}

	return (
		<div className="guest-donate">
			<div className="guest-donate__col">
				<Transition dependencyKey={confirm}>
					{ !confirm &&
						<div className="guest-donate__form">
							<Form
								submit={handleToPayment}
								onSuccess={handleSuccess}
								prevSum={paymentState.sum}
							/>
						</div>
					}
					{ confirm &&
						<Confirm
							handlePaymentClick={handlePaymentClick}
							paymentState={paymentState}
							paymentMethods={paymentMethods}
							handleBack={handleBack}
							sum={paymentState.sum}
						/>
					}
				</Transition>
			</div>
			<div className="guest-donate__col guest-donate__col_services">
				<Services />
			</div>
		</div>
	)
}

export {
	Guest
}
