import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {NavLink, useHistory} from 'react-router-dom'
import { getUserStatus, getUserData } from 'store/user/selectors'
import { home, donate, login } from 'router/routes'
import { getGtaV } from 'router/links'
import { forceLogout } from 'store/user/actions'
import { HashLink as Link } from 'react-router-hash-link'
import { toggleFeedback } from 'store/app/actions'
import ExternalLink from 'components/Router/ExternalLink/ExternalLink'
import Transition from 'components/Transition/Transition'

const Nav: FC = () => {
	const history = useHistory()
	const isAuth = useSelector(getUserStatus)
	const user = useSelector(getUserData)
	const [ active, setActive ] = useState(false)
	const [ showMenu, setShowMenu ] = useState(false)
	const navContainerClasses = useMemo(() => {
		return `nav-main${active ? ' nav-main_active' : ''}`
	}, [active])
	const burgerClasses = useMemo(() => {
		return `hamburger hamburger--arrow${active ? ' is-active' : ''}`
	}, [active])
	console.log(history)

	const dispatch = useDispatch()

	const handleClick = () => {
		setActive(!active)
	}

	const offlineNav = () => {
		setActive(false)
	}

	const toggleMenu = () => {
		setShowMenu(!showMenu)
	}

	const handleFeedback = () => {
		offlineNav()
		dispatch(toggleFeedback(true))
	}

	const handleLogout = () => {
		dispatch(forceLogout())
		setShowMenu(!showMenu)
	}

	const handleDonate = () => {
		history.push('/donate')
		setShowMenu(!showMenu)
	}

	const hideOnGame = useMemo(() => {
		return history.location.pathname.startsWith('/donate/from/game')
	}, [history.location])

	return (
		<nav className={navContainerClasses} id="nav">
			<ul className="nav-main__items">
				<li className="nav-main__item nav-main__item_primary">
					{ !hideOnGame &&
						<NavLink to={home} onClick={offlineNav}>
							<div className="logo" />
						</NavLink>
					}
					{ hideOnGame &&
          	<div className="logo" />
					}
					<button
						className={burgerClasses}
						type="button"
						id="nav-toggle"
						onClick={handleClick}
					>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</button>
				</li>
				{ !hideOnGame &&
        	<li className="nav-main__item nav-main__item_links">
          <nav className="nav-links">
            <ul className="nav-links__items">
              <li className="nav-links__item">
                <Link to="/#play" smooth={true} className="nav-links__link">
                  Начать играть
                </Link>
              </li>
              <li className="nav-links__item">
                <ExternalLink href={getGtaV} className="nav-links__link">
                  Купить GTA V
                </ExternalLink>
              </li>
              <li className="nav-links__item">
                <NavLink to={donate} className="nav-links__link">
                  Донат
                </NavLink>
              </li>
              <li className="nav-links__item" onClick={handleFeedback}>
                <div className="nav-links__link">
                  Обратная связь
                </div>
              </li>
              <li className="nav-links__item">
								{ isAuth === 'pending' &&
                <NavLink to={login} className="nav-links__link nav-links__link_login">
                  ВЫПОЛНЯЕМ ВХОД
                </NavLink>
								}
								{ !isAuth &&
                <NavLink to={login} className="nav-links__link nav-links__link_login" onClick={offlineNav}>
                  Войти
                  <i className="icon icon__arrow-right" />
                </NavLink>
								}
								{ isAuth === true &&
                <div className="nav-links__link nav-links__link_login" onClick={toggleMenu}>
									{ user.login }
                  <i className="icon icon__user" />
                </div>
								}
              </li>
              <Transition dependencyKey={showMenu} transition="fromTop">
								{ showMenu &&
                <li className="profile-menu">
                  <div className="profile-menu__balance">
                    <i className="icon__wallet" />
                    <div className="profile-balance">
                      <p className="profile-balance__about">Баланс:</p>
                      <span>{ user.donate } DP</span>
                    </div>
                  </div>
                  <div className="profile-menu__item" onClick={handleDonate}>
											<span>
												Пополнить
											</span>
                  </div>
                  <div className="profile-menu__item" onClick={handleLogout}>
											<span>
												Выйти
											</span>
                  </div>
                </li>
								}
              </Transition>
            </ul>
          </nav>
        </li>
				}
			</ul>
		</nav>
	)
}

export default Nav
