import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import Title from 'components/Template/Title/Title'
import Button from 'components/Entities/Form/Button/Button'

const PaymentFailure: FC = () => {
	return (
		<div className="payment-status">
			<Title
				gradient={true}
				bg={true}
				bgTitle="ПРОИЗОШЛА ОШИБКА"
				centered={true}
				className="payment-status__title"
			>
				ПРОИЗОШЛА ОШИБКА
			</Title>
			<p className="payment-status__text">
				Попробуйте оплатить счет другим способом или повторите позже.
			</p>
			<div className="payment-status__btn">
				<NavLink to="/donate">
					<Button>
						Вернуться к оплате
					</Button>
				</NavLink>
			</div>
		</div>
	)
}

export default PaymentFailure
