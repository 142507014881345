import React, { FC } from 'react'
import { useState } from 'react'

import Card from 'components/Donate/Card/Card'
import PaymentMethods from 'components/Donate/PaymentMethods/PaymentMethods'
import { IPaymentMethods } from 'interfaces/IPaymentMethods'
import { IPaymentState } from 'interfaces/IPaymentState'

interface IConfirmProps extends IPaymentMethods {
	handlePaymentClick: Function
	paymentState: IPaymentState
	handleBack: Function | null,
	sum?: number,
	server?: string
}

const Confirm: FC<IConfirmProps> = (
	{
		paymentState,
		handlePaymentClick,
		paymentMethods,
		handleBack,
		sum = 0
	}) => {

  const [
    isPromo,
    setIsPromo
  ] = useState(false)

	return (
		<div className="donate-confirm">
			<Card
        paymentType={paymentState.paymentType}
        login={paymentState.login}
        donateType={paymentState.donateType}
        donateSum={paymentState.sumWithBonus}
        sum={paymentState.sum}
        handleBack={handleBack}
        server={paymentState.server}
        isPromo={isPromo}
        setIsPromo={setIsPromo}
			/>
			<div className="donate-confirm__payments">
				<PaymentMethods
          handlePaymentClick={handlePaymentClick}
          paymentMethods={paymentMethods}
          sum={sum}
          togglePromo={() => setIsPromo(true)}
				/>
			</div>
		</div>
	)
}

export default Confirm
