const discord = 'https://discord.gg/zeDw2dsQ'
const vk = 'https://vk.com/gta5rp'
const youtube = '/'
const getGtaV = 'https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/?l=russian'

export {
	discord,
	vk,
	youtube,
	getGtaV,
}
