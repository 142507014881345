import React, {FC, Fragment, HTMLAttributes, useMemo} from 'react'
import { Server as ServerEntity } from 'components/Entities/Server/Server'
import './server.scss'

interface IContainerProps {
	players: number,
	status: boolean,
}

const Container: FC<IContainerProps> = (
	{ players,
		status
	}) => {

	const classNames = useMemo(() => {
		return`home-server__online
			${status
				?'home-server__online_engaged'
				: 'home-server__online_disengaged'
			}`
	}, [status])

	const playersPhrase = useMemo(() => {
		if (!status) {
			return 0
		}
		if (players > 1700) {
			return 1700
		}
		return `Онлайн ${players}`
	}, [players, status])

	return (
		<div className={classNames}>
			<span>
				{ status &&
					<Fragment>
						{ players > 1700 &&
							<Fragment>
                Онлайн { playersPhrase }
                <p>({ players - 1700 } в очереди)</p>
							</Fragment>
						}
						{ players <= 1700 && playersPhrase }
					</Fragment>
				}
				{ !status && 'Сервер оффлайн'}
			</span>
		</div>
	)
}

interface IFooterProps {
	players: number,
	status: boolean,
}

const Footer: FC<IFooterProps> = ({ players, status }) => {
	const playersPercent = useMemo(() => {
		if (!status) return 0
		if (players >= 1700) return 100
		return players / 1700 * 100
	}, [players])

	const bgStyles = useMemo(() => {
		return {
			width: `${status ? playersPercent : 0}%`
		}
	}, [playersPercent])

	return (
		<div className="online-bar">
			<span className="online-bar__value">
				{ status ? `Онлайн ${ players >= 1700 ? 1700 : players } / 1700` : 'OFFLINE'}
			</span>
			<div className="online-bar__bg" style={bgStyles} />
		</div>
	)
}

interface IServerProps extends HTMLAttributes<HTMLDivElement> {
	serverId: number,
	serverName: string,
	background: string,
	players: number,
	status: boolean,
}

const Server: FC<IServerProps> = (
	{ serverId,
		serverName,
		background,
		players,
		status,
		...restProps
	}) => {

	return (
		<div className="home-server">
			<ServerEntity
				serverId={serverId}
				serverName={serverName}
				background={background}
				toContainer={<Container players={players} status={status} />}
				toFooter={<Footer players={players} status={status} />}
				{ ...restProps }
			/>
		</div>
	)
}

export default Server
