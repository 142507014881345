import React, { FC } from 'react'
import Title from 'components/Template/Title/Title'
import { getGtaV, discord } from 'router/links'
import {HashLink, HashLink as Link} from 'react-router-hash-link';

const Play: FC = () => {
	return (
		<div className="play" id="play">
			<Title
				gradient={true}
				bg={true}
				bgTitle="Как начать"
				className="play__title"
			>
				Как начать играть
			</Title>
			<div className="play-steps">
				{ /* Первый шаг */ }
				<div className="play-step play-step_blue">
					<div className="play-step__container">
						<div className="play-step__pic">
							<img
								alt="gta5rp game"
								src="/assets/images/gtav.svg"
							/>
						</div>
						<div className="play-step__about">
							<p>
								Купи и установи <br />
								лицензионную игру GTA V
							</p>
						</div>
						<div className="play-step__bg" />
					</div>
					<a href={getGtaV} target="_blank">
						<button className="play-step__btn">
							Купить в Steam
						</button>
					</a>
				</div>
				{ /* Второй шаг */ }
				<div className="play-step play-step_sea">
					<div className="play-step__container">
						<div className="play-step__pic">
							<img
								src="/assets/images/monitor.png"
								alt="monitor gta5rp"
							/>
						</div>
						<div className="play-step__about">
							<p>
								Подключись к официальному<br />
								серверу в Discord
							</p>
						</div>
						<div className="play-step__bg" />
					</div>
					<a href={discord} target="_blank">
						<button className="play-step__btn">
							Discord сервер
						</button>
					</a>
				</div>
				{ /* Третий шаг */ }
				<div className="play-step play-step_orange">
					<div className="play-step__container">
						<div className="play-step__pic">
							<img
								src="/assets/images/video.png"
								alt="monitor gta5rp"
							/>
						</div>
						<div className="play-step__about">
							<p>
								Подключись к игровым <br />
								серверам и играй с тысячами <br />
								игроков онлайн
							</p>
						</div>
						<div className="play-step__bg" />
					</div>
					<HashLink to="/#servers" smooth={true}>
						<button className="play-step__btn">
							Играть онлайн
						</button>
					</HashLink>
				</div>
			</div>
		</div>
	)
}

export default Play
