import React, {FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import { getUserData } from 'store/user/selectors'
import Transition from 'components/Transition/Transition'
import Title from 'components/Template/Title/Title'
import Input from 'components/Entities/Form/Input/Input'
import Button from 'components/Entities/Form/Button/Button'
import {getFeedback} from 'store/app/selectors'
import {sendFeedback, toggleFeedback} from '../../store/app/actions';
import {useErrorMessage} from '../../hooks/useErrorMessage';
import {requestDispatch} from '../../helpers/request';

const Feedback: FC = () => {
	const isShow = useSelector(getFeedback)
	const userData = useSelector(getUserData)

	useEffect(() => {
		const $el = document.getElementsByTagName('html')[0]
		if (isShow) {
			$el.classList.add('html_locked')
		} else {
			$el.classList.remove('html_locked')
		}
	}, [isShow])

	const {
		register,
		handleSubmit,
		errors,
		formState,
		trigger,
		getValues,
		setError,
	} = useForm({
		mode: "onChange",
		criteriaMode: "all",
		defaultValues: {
			login: userData.login,
			email: userData.email,
			text: null,
			sid: 100,
		}
	})
	const [ errorMessage, setErrorMessage ] = useErrorMessage(formState, errors)
	const [ isSent, setIsSent ] = useState(false)

	const dispatch = useDispatch()

	const handleControl = () => {
		dispatch(toggleFeedback(false))
	}

	const onSubmit = async () => {
		const data = getValues()
		setIsSent(true)
		try {
			const response: any = await requestDispatch(sendFeedback(data), onSubmit)
			if (!response.success) {
				throw { message: response.error_text, errors: response.errors }
			}
			setIsSent(false)
		} catch ({ message, errors }) {
			if (errors) {
				const firstKey = Object.keys(errors)[0]
				if (firstKey) {
					//@ts-ignore
					setError(firstKey, { type: "backendError", message: errors[firstKey] });
				}
			}
		}
		setIsSent(false)
	}

	useEffect(() => {
		trigger()
	}, [trigger])

	return (
		<Transition dependencyKey={isShow}>
			{ isShow &&
				<div className="modal">
					<div className="modal-root">
            <div className="modal__container">
							<i
									onClick={handleControl}
									className="icon__close-modal modal__control"
							/>
              <Title
                  gradient={true}
                  centered={true}
                  className="modal__title"
              >
                Обратная связь
              </Title>
              <form className="feedback-form" onSubmit={handleSubmit(onSubmit)}>
                <Input
                    id="login"
                    name="login"
                    type="login"
                    autoComplete="off"
                    label="ваш логин"
                    validate={register({ required: 'Введите логин!'})}
                />
                <div className="ff-group">
                  <Input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="off"
                      label="ваш e-mail"
                      validate={register({ required: 'Введите e-mail!'})}
                  />
                </div>
								<input
										type="hidden"
										name="sid"
										id="sid"
										ref={register({ required: "Ошибка" })}
										defaultValue={200}
								/>
                <div className="ff-group">
                  <label htmlFor="" className="textarea-label">
                    Текст сообщения
                  </label>
                  <textarea
											id="text"
											name="text"
											className="textarea-control"
                      ref={register({ required: 'Введите текст сообщения!'})}
									/>
                </div>
                <Transition dependencyKey={errorMessage}>
                  <p className="donate-error form-error mt-18">
										{ errorMessage }
                  </p>
                </Transition>
              </form>
            </div>
            <Button
								className="feedback-form__submit"
								disabled={!formState.isValid || isSent}
								onClick={onSubmit}
						>
              отправить
            </Button>
					</div>
				</div>
			}
		</Transition>
	)
}

export default Feedback
