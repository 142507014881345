import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import Server from '../Server/Server'
import IServer from 'interfaces/IServer'
import { getServerList } from 'store/app/selectors'

const Success: FC = () => {
	const servers = useSelector(getServerList)

	return (
		<div className="home-servers__list">
			{ servers.map((server: IServer, id: number) =>
				<Server
					serverId={server.id}
					key={server.id}
					serverName={server.name}
					players={server.players}
					background={server.background}
					status={server.status}
				/>
			) }
		</div>
	)
}

export default Success
