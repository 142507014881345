const initialState = {
	requestLoader: false,
	serversLoading: false,
	servers: [],
	payments: [],
	paymentsFetchingStatus: 'pending',
	packs: [],
	packsFetchingStatus: 'pending',
	donateScenario: 'sleep',
	rolesFetchingStatus: 'pending',
	fundHash: '',
	fundFetchingStatus: 'pending',
	roles: [],
	feedback: false,
	fund: null,
	services: [
		{
			name: 'Покупка игровых бонусов DP',
			cost: '1 DP = 1 ₽',
			auth: false,
		},
		{
			name: 'VIP статус на 30 дней',
			cost: '399 ₽',
			auth: false,
		},
		{
			name: 'Gold VIP статус на 30 дней',
			cost: '999 ₽',
			auth: false,
		},
		{
			name: 'Военный билет для персонажа',
			cost: '1000 ₽',
			auth: false,
		},
		{
			name: 'Снятие варна с персонажа',
			cost: '300 ₽',
			auth: false,
		},
		{
			name: 'Смена имени персонажа',
			cost: '30 ₽',
			auth: false,
		},
		{
			name: 'Смена внешности персонажа',
			cost: '150 ₽',
			auth: false,
		},
		{
			name: 'Смена возраста персонажа',
			cost: '10 ₽',
			auth: false,
		},
		{
			name: 'Убрать наркозависимость',
			cost: '100 ₽',
			auth: true,
		},
	],
}

export default initialState
