import React, {
  FC,
  useRef,
  useCallback,
  useState,
  Fragment,
} from 'react'
import type { ChangeEvent } from 'react'

import CardItem from './CardItem/CardItem'
import Transition  from '../../Transition/Transition'
import Button from '../../Entities/Form/Button/Button'
import Input from '../../Entities/Form/Input/Input'
import { activateDonate } from '../../../store/app/actions'
import './card.scss'
import {useDispatch} from 'react-redux'

interface ICardProps {
	paymentType?: string,
	login: string,
	server?: string | null,
	donateType?: string,
	role?: string | null,
	donateSum: number | null,
	sum: number | null,
	handleBack: Function | null
  isPromo?: boolean
  setIsPromo: (promo: boolean) => void
}

const Card: FC<ICardProps> = (
	{ paymentType = 'Пополнение счета',
		login,
		server = null,
		donateType = 'Пополнение',
		role = null,
		donateSum,
		sum,
		handleBack,
    isPromo,
    setIsPromo,
	}) => {
  const dispatch = useDispatch()

  const inputValue = useRef('')

  const handleInput = useCallback((
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target

    inputValue.current = value
  }, [])

  const [
    error,
    setError
  ] = useState('')

  const handleSubmit = async () => {
    const response = await dispatch(activateDonate({
      key: inputValue.current
    }))

    if (!response) return

    console.log(response)

    //@ts-ignore
    if (!response?.success) {
      //@ts-ignore
      setError(response.error_text)
      return
    }

    setError('')
    //@ts-ignore
    alert(response?.text || 'Код успешно активирован')
  }

  //@ts-ignore
  const handleClick = (e) => {
    e.preventDefault()

    if (isPromo) {
      setIsPromo(false)
      return
    }

    // @ts-ignore
    handleBack()
  }

  return (
    <div className="donate-card">
      { handleBack &&
        <div className="donate-card__back" onClick={handleClick}>
          <i className="icon icon__arrow-left" />
        </div>
      }
      <div className="donate-card__root">
        <div className="donate-card__role" />
        <p className="donate-card__name">
          { login }
        </p>
        <p className="donate-card__about">
          { isPromo && 'Активация ключа' }
          { !isPromo && paymentType }
        </p>
        <Transition dependencyKey={isPromo}>
          { !isPromo && (
            <div>
              <div className="donate-card__container">
                { server && <CardItem name="Сервер" value={server} /> }
                <CardItem name="Тип товара" value={donateType} />
                { role && <CardItem name="Персонаж" value={role} /> }
                <CardItem name="Количество" value={`${donateSum} DP`} />
              </div>
              <div className="donate-card__sum">
                <p>
                  Сумма к оплате: <strong>{ sum } руб.</strong>
                </p>
              </div>
            </div>
          ) }
          { isPromo && (
            <div>
              <div
                className="donate-card__container"
                style={{marginTop: '20px'}}
              >
                <a
                  target="_blank"
                  href="https://www.kinguin.net/c/124940/5rp"
                >
                  <Button>
                    Купить ключ
                  </Button>
                </a>
                <Transition dependencyKey={error}>
                  { !error && (
                    <label
                      htmlFor="donateInput"
                      className="donate-card__label"
                    >
                      Введите ключ
                    </label>
                  ) }

                  { error && (
                    <label
                      htmlFor="donateInput"
                      className={'donate-card__label' + ' donate-card__label_errored'}
                    >
                      { error }
                    </label>
                  ) }
                </Transition>
                <input
                  id="donateInput"
                  className="donate-card__input"
                  onChange={handleInput}
                />
                {/*{ server && <CardItem name="Сервер" value={server} /> }*/}
                {/*<CardItem name="Тип товара" value={donateType} />*/}
                {/*{ role && <CardItem name="Персонаж" value={role} /> }*/}
                {/*<CardItem name="Количество" value={`${donateSum} DP`} />*/}
              </div>
              <button
                type="button"
                className={'donate-card__sum' + ' donate-card__sum_code'}
                onClick={handleSubmit}
              >
                <p>
                  Активировать
                </p>
              </button>
            </div>
          ) }
        </Transition>
      </div>
    </div>
  )
}

export default Card
