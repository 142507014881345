import React, { FC } from 'react'
import { useTokenControl } from 'hooks/useTokenControl'
import Template from 'components/Template/Template'
import { Controller as RouterController } from 'components/Router/Controller/Controller'
import Captcha from 'components/Captcha/Captcha'
import Feedback from './components/Feedback/Feedback'

const App: FC = () => {
  useTokenControl()
  return (
    <div className="page-container">
      <Template>
        <RouterController />
        <Captcha />
        <Feedback />
      </Template>
    </div>
  )
}

export default App
