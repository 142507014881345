import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ExternalLink from 'components/Router/ExternalLink/ExternalLink'
import { toggleFeedback } from 'store/app/actions'
import { home } from 'router/routes'
import { discord, vk, youtube } from 'router/links'

const Footer: FC = () => {
	const dispatch = useDispatch()

	const handleFeedback = () => {
		dispatch(toggleFeedback(true))
	}

	return (
		<footer className="footer-main">
			<div className="footer-main__container">
				<div className="footer-main__row">
					<div className="footer-main__col">
						<NavLink to={home}>
							<div className="icon footer-logo" />
						</NavLink>
					</div>
					<div className="footer-main__col">
						<nav className="footer-social">
							<ul className="footer-social__items">
								<li className="footer-social__item">
									<ExternalLink href={discord} className="footer-social__link">
										<i className="icon icon__footer-discord" />
									</ExternalLink>
								</li>
								<li className="footer-social__item">
									<ExternalLink href={vk} className="footer-social__link">
										<i className="icon icon__footer-vk" />
									</ExternalLink>
								</li>
								<li className="footer-social__item">
									<ExternalLink href={youtube} className="footer-social__link">
										<i className="icon icon__footer-youtube" />
									</ExternalLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className="footer-main__row">
					<div className="footer-main__col">
						<nav className="footer-nav footer-nav_long">
							<ul className="footer-nav__items">
								<li className="footer-nav__item">
									<div onClick={handleFeedback} className="footer-nav__link">
										Обратная связь
									</div>
								</li>
								<li className="footer-nav__item footer-nav__item_user-agreement">
									<a
										href="https://gta5rp-game.com/docs/oferta.pdf"
										className="footer-nav__link"
										target="_blank"
									>
										Пользовательское соглашение
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<div className="footer-main__col">
						<nav className="footer-nav">
							<ul className="footer-nav__items">
								<li className="footer-nav__item">
									<a href="https://gta5rp-game.com/docs/policy.pdf" className="footer-nav__link" target="_blank">
										Политика обработки персональных данных
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className="footer-main__row">
					<div className="footer-main__col footer-nav__link" style={{'cursor': 'default', textAlign: 'center', marginTop: '1em'}}>
						ООО "ИГРОВЫЕ ТЕХНОЛОГИИ" ОГРН 1201600081187, ИНН 1655447391
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer