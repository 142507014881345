export const useBonus = () => {

	const calculateBonus = (sum: string | number) => {
		const numberSum = Number(sum)
		if (numberSum < 0) {
			return 0
		}
		let localSum = numberSum
		if (numberSum >= 100000) {
			const bonus = numberSum / 100 * 50
			localSum += bonus
			return localSum
		}
		if (numberSum >= 15000) {
			const bonus = numberSum / 100 * 25
			localSum += bonus
			return localSum
		}
		if (numberSum >= 5000) {
			const bonus = numberSum / 100 * 15
			localSum += bonus
			return localSum
		}
		return localSum
	}

	return [ calculateBonus ]
}
