import React, { FC, Fragment } from 'react'

import Title from 'components/Template/Title/Title'
import Fail from 'components/Pages/Servers/Fail/Fail'
import Pending from 'components/Pages/Servers/Pending/Pending'
import Success from 'components/Pages/Servers/Success/Success'
import Controller from 'components/Entities/Gateway/Controller/Controller'
import { useRequestChain } from 'hooks/useRequestChain'
import { serverListScenario } from 'store/app/scenarios'

const Servers: FC = () => {
	const serversScenario = useRequestChain(serverListScenario())

	return (
		<Fragment>
			<Title className="servers__title" gradient={true}>
				Выберите сервер<br />
				И нажмите «Пополнить счет»
			</Title>
			<Controller
				onWaiting={<Pending />}
				onError={<Fail />}
				onSuccess={<Success />}
				dependencyKey={serversScenario}
			/>
		</Fragment>
	)
}

export default Servers
