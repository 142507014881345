import IState from './types/IState'

const state: IState = {
	auth: false,
	token: localStorage.getItem('token'),
	data: {
		login: null,
		email: null,
		donate: null
	},
}

export default state
