import React, { FC } from 'react'
import Title from 'components/Template/Title/Title'

const PaymentSuccess: FC = () => {
	return (
		<div className="payment-status">
			<Title
				gradient={true}
				bg={true}
				bgTitle="Успешно оплачено"
				centered={true}
				className="payment-status__title"
			>
				Вы успешно оплатили счет
			</Title>
			<p className="payment-status__text">
				Зайдите на сервер и проверьте ваш баланс (F10 - Магазин)
			</p>
		</div>
	)
}

export default PaymentSuccess
