import React, {FC, useState, useEffect, useMemo, useRef, HTMLAttributes} from 'react'
import './select.scss'

interface PaymentsProps extends HTMLAttributes<HTMLInputElement> {
	payments: Array<string>,
	name: string,
	validate?: any,
}

const Select: FC<PaymentsProps> = (
	{ payments,
		name,
		validate,
		...restProps }) => {
	const [ active, setActive ]: any = useState(false)
	const paymentsContainer = useRef<HTMLDivElement>(null)
	const [ selected, setSelected ]: any = useState(payments[0])

	useEffect(() => {
		if (active) {
			const $el = paymentsContainer.current
			if ($el) {
				$el.style.bottom = `-${$el.scrollHeight}px`
			}
		}
	}, [active])

	useEffect(() => {
		setActive(false)
	}, [selected])

	const paymentsClasses = useMemo(() => {
		return active ? 'payments-list_active' : ''
	}, [active])

	const handleToggleState = () => {
		setActive(!active)
	}

	const handleSelect = (el: any) => {
		setSelected(el)
	}

	return (
		<div
			className="input-wrapper"
			tabIndex={active ? 1 : 0}
			onClick={handleToggleState}
		>
			<label className="input-label">
				Выберите персонажа
			</label>
			<div
				className="input-container"
			>
				<div className="payments-root">
					<div className="payments-selected__container payments-type__container">
						<span className="payments-selected payments-selected__name">
								{ selected.name }
						</span>
					</div>
				</div>
			</div>
			<input
				type="hidden"
				ref={validate}
				name={name}
				defaultValue={selected.mid}
				{ ...restProps }
			/>
			<div className={`payments-list ${paymentsClasses}`} ref={paymentsContainer}>
				{ payments.map(({ mid, name }: any) => (
					<div
						key={mid}
						className="payments-type"
						onClick={(e) => handleSelect({ mid, name })}
					>
						<div className="payments-type__container">
							<span>
								{ name }
							</span>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Select
