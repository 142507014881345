import IState from './types/IState'

const getState = (state: any) => state.user

export const getUserStatus = (state: IState) => {
	const { auth, token } = getState(state)

	if (!auth && token) {
		return 'pending'
	}

	return !!(auth && token);

}
export const getUserToken = (state: IState) => getState(state).token
export const getUserData = (state: IState) => getState(state).data
