import React, { FC } from 'react'
import Preloader from 'components/Entities/Preloader/Preloader'
import './pendingProfile.scss'

const PendingProfile: FC = () => {
	return (
		<div className="user-pending">
			<Preloader />
			<span>
				Загрузка профиля пользователя
			</span>
		</div>
	)
}

export default PendingProfile
