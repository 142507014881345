import React, { FC } from 'react'
import Play from 'components/Pages/Home/Play/Play'
import Servers from 'components/Pages/Home/Servers/Servers'
import Button from 'components/Entities/Form/Button/Button'
import ExternalLink from 'components/Router/ExternalLink/ExternalLink'
import { discord, vk, youtube } from 'router/links'

const Home: FC = () => {
	return (
		<>
			<div className="home-content">
				<div className="home-outline" />
				<h1 className="home-title">
					<strong>gta5rp</strong> game
				</h1>
				<h2 className="home-subtitle">
					Начни увлекательное приключение в мире GTA5RP
				</h2>
				<i className="icon icon__scroll-down home__scroll-down" />
			</div>
			<div className="home-social">
				<nav className="social-nav">
					<ul className="social-nav__items">
						<li className="social-nav__item">
							<ExternalLink href={discord} className="social-nav__link">
								<i className="icon icon__social icon__social_discord" />
							</ExternalLink>
						</li>
						<li className="social-nav__item">
							<ExternalLink href={vk} className="social-nav__link">
								<i className="icon icon__social icon__social_vk" />
							</ExternalLink>
						</li>
						<li className="social-nav__item">
							<ExternalLink href={youtube} className="social-nav__link">
								<i className="icon icon__social icon__social_youtube" />
							</ExternalLink>
						</li>
					</ul>
				</nav>
			</div>
			<div className="home-role">
				<img src="/assets/images/home/role.png" />
			</div>
			<div className="home-el">
				<img src="/assets/images/home/el.png" />
			</div>
			<Play />
			<Servers />
			<div className="home-social__action">
				<ExternalLink href={discord}>
					<Button>
						Подключиться в Discord
					</Button>
				</ExternalLink>
			</div>
		</>
	)
}

export default Home
