import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Pending as PendingState } from 'components/Entities/Gateway/Pending/Pending'
import { getFundFetchingStatus } from 'store/app/selectors'

const Pending: FC = () => {
	const fundStatus = useSelector(getFundFetchingStatus)

	const pendingStatus = useMemo(() => {
		if (fundStatus === 'pending') {
			return 'fund'
		}
		return 'success'
	}, [fundStatus])

	return (
		<PendingState>
			{ pendingStatus === 'fund' && 'Загружаем данные персонажа!' }
			{ pendingStatus === 'success' && 'Переход на форму оплаты' }
		</PendingState>
	)
}

export default Pending
