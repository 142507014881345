import React, { FC } from 'react'

import { IDonatePacks } from 'interfaces/IDonatePacks'

interface IPacksProps {
	packs: IDonatePacks,
	handleClick: Function
}

const Packs: FC<IPacksProps> = ({ packs, handleClick }) => {
	return (
		<div className="packs">
			<div className="packs__col packs__col_invisible packs__col_thead">
				<div className="packs__row packs__row_large">
				</div>
				<div className="packs__row pack__typed pack__start">
					Стартовый
				</div>
				<div className="packs__row pack__typed pack__pro">
					Профи
				</div>
				<div className="packs__row pack__typed pack__authority">
					Авторитет
				</div>
				<div className="packs__row pack__typed pack__boss">
					Босс
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					Игровая валюта
				</div>
				<div className="packs__row">
					20 000$
				</div>
				<div className="packs__row">
					35 000$
				</div>
				<div className="packs__row">
					75 000$
				</div>
				<div className="packs__row">
					250 000$
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					СЛУЧАЙНАЯ ШАПКА ИЛИ АКСессУАР
				</div>
				<div className="packs__row">
					{/*<i className="icon__donate" />*/}
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					Военный билет
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col packs__col_disabled">
				<div className="packs__row packs__row_large">
					Права на:
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					автомобиль
				</div>
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					мотоциклы
				</div>
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					грузовые т/c
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					САМОЛЕТЫ
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					ВОДНые т/c
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col packs__col_disabled">
				<div className="packs__row packs__row_large">
					Лицензия на:
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					ОРУЖИЕ
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col">
				<div className="packs__row packs__row_large">
					Бизнес
				</div>
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row" />
				<div className="packs__row">
					<i className="icon__donate" />
				</div>
			</div>
			<div className="packs__col packs__col_margin packs__col_invisible">
				<div className="packs__row packs__row_large" />
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_blue"
						onClick={() => handleClick(packs[0])}
					>
						{ packs[0].price } руб.
					</button>
					<p className="packs__bonus">
						Бонус +40 DP на баланс
					</p>
				</div>
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_yellow"
						onClick={() => handleClick(packs[1])}
					>
						{ packs[1].price } руб.
					</button>
					<p className="packs__bonus">
						Бонус +50 DP на баланс
					</p>
				</div>
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_purple"
						onClick={() => handleClick(packs[2])}
					>
						{ packs[2].price } руб.
					</button>
					<p className="packs__bonus">
						Бонус +150 DP на баланс
					</p>
				</div>
				<div className="packs__row">
					<button
						type="button"
						className="packs__action packs__action_orange"
						onClick={() => handleClick(packs[3])}
					>
						{ packs[3].price } руб.
					</button>
					<p className="packs__bonus">
						Бонус +999 DP на баланс
					</p>
				</div>
			</div>
		</div>
	)
}

export default Packs
