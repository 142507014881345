import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import Service from './Service/Service'
import { getServices } from 'store/app/selectors'
import { IService } from 'interfaces/IService'
import './services.scss'

const Services: FC = () => {
	const services = useSelector(getServices)

	return (
		<div className="donate-services">
			<h2 className="donate-services__title title_gradient">
				Список услуг:
			</h2>
			{ services.map(({ name, cost, auth }: IService) => (
				<Service
					key={name}
					name={name}
					cost={cost}
					auth={auth}
				/>
			))}
		</div>
	)
}

export default Services
