import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Confirm from 'components/Donate/Confirm/Confirm'
import {getFund, getFundHash} from 'store/app/selectors'
import { gamePayment } from 'store/app/actions'
import { useBonus } from 'hooks/useBonus'
import { IPaymentState } from 'interfaces/IPaymentState'
import './game.scss'

const Game: FC = () => {
	const dispatch = useDispatch()
	const fund = useSelector(getFund)
	console.log(fund)
	const fundHash = useSelector(getFundHash)
	const [ calculateBonus ] = useBonus()
	const paymentMethods = fund.methods
	const [ paymentState, setPaymentState ] = useState<IPaymentState>({
		key: fund.entity.key,
		login: fund.entity.name,
		sum: fund.entity.sum,
		sumWithBonus: calculateBonus(fund.entity.sum)
	})

	const handlePayment = async (id: string) => {
		const data = {
			fund_hash: '',
			payment_method: '',
		}
		data.fund_hash = fundHash
		data.payment_method = id
		try {
			const { success, link }: any = await dispatch(gamePayment(data))
			if (success) {
				window.location.href = link
			}
		} catch (e) {}
	}

	return (
		<div className="donate-game">
			<Confirm
				handlePaymentClick={handlePayment}
				paymentState={paymentState}
				paymentMethods={paymentMethods}
				handleBack={null}
				sum={paymentState.sum}
			/>
		</div>
	)
}

export {
	Game
}
