import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import Title from 'components/Template/Title/Title'
import FormInput from 'components/Form/FormInput/FormInput'
import Button from 'components/Entities/Form/Button/Button'
import { requestDispatch } from 'helpers/request'
import { loginByPassword } from 'store/user/actions'
import { toggleRequestLoader } from 'store/app/actions'
import { useErrorMessage } from 'hooks/useErrorMessage'
import Transition from 'components/Transition/Transition'

interface IConfirmProps {
	confirmKey: string,
	onLoginSuccess: Function
}

const Confirm: FC<IConfirmProps> = ({ confirmKey, onLoginSuccess }) => {
	const dispatch = useDispatch()
	const {
		register,
		handleSubmit,
		errors,
		formState,
		trigger,
		getValues,
		setError,
	} = useForm({
		mode: "onChange",
		criteriaMode: "all",
		defaultValues: {
			code: null,
		}
	})
	const [ errorMessage, setErrorMessage ] = useErrorMessage(formState, errors)
	const [ isSent, setIsSent ] = useState<Boolean>(false)

	useEffect(() => {
		trigger()
	}, [trigger])

	const confirmRequest = async () => {
		dispatch(toggleRequestLoader(true))
		setIsSent(true)
		try {
			const data = {
				'2fa_rid': confirmKey,
				'2fa_code': getValues('code')
			}
			const response: any = await requestDispatch(loginByPassword(data), confirmRequest)
			if (!response.success) {
				throw { message: response.error_text, errors: response.errors, response }
			} else {
				onLoginSuccess(response.token)
			}
		} catch ({ message, errors, response }) {
			if (errors) {
				let firstKey = Object.keys(errors)[0]
				if (firstKey) {
					//@ts-ignore
					setError('code', { type: "backendError", message: errors[firstKey] });
				}
			}
		}
		setIsSent(false)
		dispatch(toggleRequestLoader(false))
	}

	const onSubmit = async () => {
		await confirmRequest()
	}

	return (
		<div>
			<Title
				centered={true}
				bgTitle="Подтверждение"
				className="login-title"
			>
				Подтверждение
			</Title>
			<form className="form login-form" onSubmit={handleSubmit(onSubmit)}>
				<div className="login-form__wrapper">
					<FormInput
						id="code"
						name="code"
						autoComplete="off"
						label={'Код подтверждения'}
						validate={register({ required: 'Введите код подтверждения' })}
					/>
					<Transition dependencyKey={errorMessage}>
						<p className="form-error mt-18">
							{ errorMessage }
						</p>
					</Transition>
					<div className="form-group login-form__btn-group">
						{/*@ts-ignore*/}
						<Button disabled={!formState.isValid || isSent}>
							Подтвердить
						</Button>
					</div>
				</div>
			</form>
		</div>
	)
}

export {
	Confirm
}
