import React, {FC, useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import Transition from 'components/Transition/Transition'
import Title from 'components/Template/Title/Title'
import FormInput from 'components/Form/FormInput/FormInput'
import Button from 'components/Entities/Form/Button/Button'
import Checkbox from 'components/Entities/Form/Checkbox/Checkbox'
import { requestDispatch } from 'helpers/request'
import { loginByPassword } from 'store/user/actions'
import { toggleRequestLoader } from 'store/app/actions'
import { useErrorMessage } from 'hooks/useErrorMessage'

interface ILoginProps {
	onLoginSuccess: Function,
	onUserConfirm: Function
}

const Login: FC<ILoginProps> = ({ onLoginSuccess, onUserConfirm }) => {
	const dispatch = useDispatch()
	const {
		register,
		handleSubmit,
		errors,
		formState,
		trigger,
		getValues,
		setError,
	} = useForm({
		mode: "onChange",
		criteriaMode: "all",
		defaultValues: {
			login: null,
			password: null,
			agree: true,
		}
	})
	const [ errorMessage, setErrorMessage ] = useErrorMessage(formState, errors)
	const [ isSent, setIsSent ] = useState<Boolean>(false)

	useEffect(() => {
		trigger()
	}, [trigger])

	const loginRequest = async () => {
		dispatch(toggleRequestLoader(true))
		setIsSent(true)
		try {
			const data = getValues()
			// @ts-ignore
			data.remember = 0
			const response: any = await requestDispatch(loginByPassword(data), loginRequest)
			if (!response.success) {
				throw { message: response.error_text, errors: response.errors, response }
			} else {
				onLoginSuccess(response.token)
			}
		} catch ({ message, errors, response }) {
			if (errors) {
				const firstKey = Object.keys(errors)[0]
				if (firstKey) {
					//@ts-ignore
					setError(firstKey, { type: "backendError", message: errors[firstKey] });
				}
			}
			if (message !== 'need_authcheck') {
				// @ts-ignore
				setErrorMessage(message)
				setIsSent(false)
				dispatch(toggleRequestLoader(false))
				return
			}
			setError('login', { type: "invalidLogin", message: "Требуется подтверждение" });
			onUserConfirm(response['2fa_rid'])
		}
		setIsSent(false)
		dispatch(toggleRequestLoader(false))
	}

	const onSubmit = async () => {
		await loginRequest()
	}

	return (
		<div>
			<Title
				centered={true}
				bgTitle="Авторизация"
				className="login-title"
			>
				Авторизация
			</Title>
			<form className="form login-form" onSubmit={handleSubmit(onSubmit)}>
				<div className="login-form__wrapper">
					<FormInput
						id="login"
						name="login"
						autoComplete="off"
						label={'Логин'}
						validate={register({ required: 'Заполните поле «Логин»' })}
					/>
					<FormInput
						id="password"
						name="password"
						type="password"
						label={'Пароль'}
						autoComplete="off"
						validate={register({ required: 'Заполните поле «Пароль»' })}
					/>
					<Transition dependencyKey={errorMessage}>
						<p className="form-error mt-18">
							{ errorMessage }
						</p>
					</Transition>
					<div className="form-group login-form__btn-group">
						{ /* @ts-ignore */ }
						<Button disabled={!formState.isValid || isSent}>
							Войти
						</Button>
					</div>
				</div>
				<div className="form-group form-group_centered login-accepted__wrapper">
					<Checkbox
						id="agree"
						name="agree"
						className="login-accepted"
						validate={
							register({
								required: 'Примите условия соглашения',
								setValueAs: (value) => Boolean(value)
							})
						}
					/>
					<span>
					 Принимаю условия пользовательского соглашения
					</span>
				</div>
			</form>
		</div>
	)
}

export {
	Login
}
