import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Pending as PendingState } from 'components/Entities/Gateway/Pending/Pending'
import { getPacksFetchingStatus, getPaymentsFetchingStatus } from 'store/app/selectors'

const Pending: FC = () => {
	const paymentsStatus = useSelector(getPaymentsFetchingStatus)
	const packsStatus = useSelector(getPacksFetchingStatus)

	const pendingStatus = useMemo(() => {
		if (paymentsStatus === 'pending') {
			return 'payments'
		}
		if (packsStatus === 'pending') {
			return 'packs'
		}
		return 'success'
	}, [paymentsStatus, packsStatus])

	return (
		<PendingState>
			{ pendingStatus === 'payments' && 'Загружаем способы оплаты!' }
			{ pendingStatus === 'packs' && 'Загружаем донат наборы!' }
			{ pendingStatus === 'success' && 'Переход на форму оплаты' }
		</PendingState>
	)
}

export default Pending
