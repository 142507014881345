import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { Pending as PendingState } from 'components/Entities/Gateway/Pending/Pending'
import { getRolesList, getServersStatus } from 'store/app/selectors'

const Pending: FC = () => {
	const serversStatus = useSelector(getServersStatus)
	const rolesStatus = useSelector(getRolesList)

	const pendingStatus = useMemo(() => {
		if (serversStatus === 'pending') {
			return 'servers'
		}
		if (rolesStatus === 'pending') {
			return 'roles'
		}
		return 'success'
	}, [serversStatus, rolesStatus])

	return (
		<PendingState>
			{ pendingStatus === 'servers' && 'Загружаем список серверов!' }
			{ pendingStatus === 'roles' && 'Загружаем список персонажей!' }
			{ pendingStatus === 'success' && 'Переход на список серверов' }
		</PendingState>
	)
}

export default Pending
