import initialState from "./state"
import {
	SET_USER_DATA,
	SET_USER_STATUS,
	SET_USER_TOKEN,
} from './types'
import { UserActionTypes } from './types/actions'

export const userReducer = (state = initialState, { type, payload }: UserActionTypes) => {
	switch (type) {
		case SET_USER_DATA: {
			return {
				...state,
				data: payload
			}
		}
		case SET_USER_TOKEN: {
			return {
				...state,
				token: payload
			}
		}
		case SET_USER_STATUS: {
			return {
				...state,
				auth: payload
			}
		}
		default: return state
	}
}
