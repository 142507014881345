import React, { FC } from 'react'
import { Fail as FailedState } from 'components/Entities/Gateway/Fail/Fail'

const Fail: FC = () => {
	return (
		<FailedState message="Что-то пошло не так... Попробуйте ещё раз" />
	)
}

export default Fail
